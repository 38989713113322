import React from 'react';
import './LoadingComponent.css'; // External CSS file for styling

function LoadingComponent({ height }) {
  return (
    <div className="loading-container" style={{  maxHeight:'100vh' }}>
      <div className="glass-overlay">
        <div className="bar-loader-container">
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className="loading-text">WorXstream</div>
      </div>
    </div>
  );
}

export default LoadingComponent;
