import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRequest, postRequest } from '../../api/axiosConfig';


export const createDeal = createAsyncThunk('deal/createDeal', async (data) => {
  const response = await postRequest('https://api.worxstream.io/sales/deal/create', data);
  return response.data;
});

export const dealList = createAsyncThunk('deal/dealList', async (endpointURL) => {
  const response = await getRequest(endpointURL);
  return response.data.result.list;
});

export const deleteDeal = createAsyncThunk('deal/deleteDeal', async (endpointURL) => {
  const response = await postRequest(endpointURL);
  return response.data;
});

export const cloneDeal = createAsyncThunk('deal/cloneDeal', async (endpointURL) => {
  const response = await postRequest(endpointURL);
  return response.data;
});

export const fetchDealById = createAsyncThunk('deal/fetchDealById', async (endpointURL) => {
  const response = await getRequest(endpointURL);
  return response.data.result;
});

export const updateDeal = createAsyncThunk('deal/updateDeal', async (data) => {
  const response = await postRequest('https://api.worxstream.io/sales/deal/update', data);
  return response.data;
});

export const fetchDealType = createAsyncThunk('deal/fetchDealType', async (endpointURL) => {
  const response = await getRequest(endpointURL);
  return response.data.result;
});

export const fetchDealSource = createAsyncThunk('deal/fetchDealSource', async (endpointURL) => {
  const response = await getRequest(endpointURL);
  return response.data.result;
});

const dealSlice = createSlice({
  name: 'deal',
  initialState: {
    dealData: {},
    dealListData: [],

  },
  reducers: {
    setDealDatatoStore: (state, action) => {
      state.dealData = action.payload;
    },
    setDealListDatatoStore: (state, action) => {
      state.dealListData = action.payload;
    },
    clearDealData: (state) => {
      state.dealData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createDeal.fulfilled, (state, action) => {
      state.dealData = action.payload;
    });
    builder.addCase(dealList.pending, (state, action) => {
      state.dealListData = [];
    });
    builder.addCase(dealList.fulfilled, (state, action) => {
      state.dealListData = action.payload;
    });
  },

});

export const { setDealDatatoStore, clearDealData,setDealListDatatoStore } = dealSlice.actions;
export default dealSlice.reducer;
