import { CloseOutlined, InfoOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '../../assets/images/icon-drag-indicator2.svg';
import { uniq, uniqBy } from 'lodash';

const ColumnCustomization = ({ showModal, setShowModal, showColumns, hideColumns, finalizeColumnSelection, tableType }) => {
  const formattedTableType = tableType.replace(/_/g, ' ');
  
  // Local state to hold column list with visibility
  const [tempColumns, setTempColumns] = useState([]);

  useEffect(() => {
    if (showModal) {
      // Initialize local state with both visible and hidden columns
      const initialColumns = [
        ...showColumns.map(col => ({ name: col, isVisible: true })),
        ...hideColumns.map(col => ({ name: col, isVisible: false }))
      ];
      setTempColumns( uniqBy(initialColumns, "name"));
    }
  }, [showModal, showColumns, hideColumns]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    // Reorder within the single list
    const reorderedColumns = Array.from(tempColumns);
    const [movedColumn] = reorderedColumns.splice(source.index, 1);
    reorderedColumns.splice(destination.index, 0, movedColumn);

    setTempColumns(reorderedColumns);
  };

  const toggleColumnVisibility = (index) => {
    // Toggle visibility of a specific column
    const updatedColumns = [...tempColumns];
    updatedColumns[index].isVisible = !updatedColumns[index].isVisible;
    setTempColumns(uniq(updatedColumns));
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#fafafa' : 'white',
    border: isDragging ? '1px solid #dee2e6' : 'none',
    boxShadow: isDragging ? '0px 0px 15px rgba(0, 0, 0, 0.05)' : 'none',
    ...draggableStyle,
  });

  const handleConfirmSelection = () => {
    const visibleColumns = tempColumns.filter(col => col.isVisible).map(col => col.name);
    const hiddenColumns = tempColumns.filter(col => !col.isVisible).map(col => col.name);
    finalizeColumnSelection(visibleColumns, hiddenColumns);
    setShowModal(false);
  };

  return (
    <div className={`modal editColBg ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Column Customizations</h5>
            <IconButton onClick={() => setShowModal(false)} title='Close'>
              <CloseOutlined fontSize='small' />
            </IconButton>
          </div>
          <div className="modal-body">
            <DragDropContext onDragEnd={onDragEnd}>
              <div className='d-flex gap-1 mb-1'>
                <InfoOutlined fontSize='small' className='text-secondary' />
                <p className="mb-1">Drag and drop <b>{formattedTableType}</b> columns to customize your list view. Toggle the eye icon to show or hide columns.</p>
              </div>
              <Droppable droppableId="columns">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps} className="dragDropBox">
                    {tempColumns.map((col, index) => (
                      <Draggable key={col.name} draggableId={col.name} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="d-flex align-items-center py-2 border-bottom"
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <img src={DragIndicatorIcon} alt="" />
                            <span className="flex-grow-1 mx-2">{col.name}</span>
                            <IconButton
                              onClick={() => toggleColumnVisibility(index)}
                              title={col.isVisible ? 'Hide Column' : 'Show Column'}
                            >
                              {col.isVisible ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-outline-secondary" onClick={() => setShowModal(false)}>Close</button>
            <button type="button" className="btn btn-primary" onClick={handleConfirmSelection}>Confirm Selection</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColumnCustomization;
