import React, { useEffect, useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

const TextEditor = ({ input = '', onChangeCallback = () => {}, placeholder = 'Start typing...', height = 250 }) => {
    const editor = useRef(null);
    const [content, setContent] = useState(input);

    const config = useMemo(
        () => ({
            readonly: false, // Allows editing
            placeholder: placeholder,
            height: height, // Sets editor height
            toolbarAdaptive: false,
            toolbarSticky: false,
            buttons: [
                'bold', 'italic', 'underline', 'strikethrough', '|',
                'ul', 'ol', 'outdent', 'indent', '|',
                'font', 'fontsize', 'paragraph', 'brush', '|',
                'table',
                'image', 'link', '|', 'align', 'undo', 'redo'
            ],
        }),
        [placeholder, height]
    );

    useEffect(() => {
        setContent(input);
    }, [input]);

    const handleBlur = (newContent) => {
        setContent(newContent);
        onChangeCallback(newContent); // Callback to parent component with updated content
    };

    return (
        <div className="text-editor">
            <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1} // Sets the tab index
                onBlur={handleBlur} // Handles content update
            />
        </div>
    );
};

export default TextEditor;


