import { Button, Stack } from "@mui/material";
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Search from '../../../components/Search/Search';
import TableWrapper from "../../../components/Table/TableWrapper";
import { CONTACT_LIST } from '../../../constants/keyConstants';
import { fetchContactCompanyOwnersList, fetchContactList, fetchLeadStatusDropdown, handleDataSelection } from '../../../features/table/tableSlice';
import classNames from 'classnames';
import styles from './ContactList.module.scss';

export default function ContactList() {
  const [editCell, setEditCell] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('')

  function handleDebounce(inputValue) {
    dispatch(fetchContactList({
      endpointURL: `/master/contact/list?company_id=${company_id}&user_id=${user_id}`,
      searchValue: inputValue,
    }))
  }
  const debounceFn = useCallback(debounce(handleDebounce, 500), [ dispatch]);

  useEffect(() => {
    debounceFn(searchText)
  }, [searchText , debounceFn])


  const { gridData, pagination } = useSelector(state => state.table)
  const company_id = localStorage.getItem("company_id");
  const user_id = localStorage.getItem("user_id");

  const addCustomerBtnClick = () => {
    navigate("/contact");
  };

  const onChange = (event, recordId) => {
    if (recordId !== undefined) {
      dispatch(
        handleDataSelection({
          selectedRecordId: recordId,
          allSelected: false,
          actionType: event.target.checked
        })
      );
    } else {
      dispatch(
        handleDataSelection({
          allSelected: true,
          actionType: event.target.checked
        })
      );
    }
  }

  const enableEditingMode = (record) => {
    setEditCell(record)
  }

  useEffect(() => {
    dispatch(fetchContactList({ endpointURL: `/master/contact/list?company_id=${company_id}&user_id=${user_id}&page=${pagination?.page}&take=${pagination?.perPage}` }))
    dispatch(fetchContactCompanyOwnersList(`/master/employee/dropdown?company_id=${company_id}&user_id=${user_id}`))
    dispatch(fetchLeadStatusDropdown(`/settings/option/dropdown?companyId=${company_id}&userId=${user_id}&objectName=contact`))
  }, [company_id, user_id, pagination?.page,pagination?.perPage,dispatch])

  const onSearchChangeHander = (event) => {
    setSearchText(event.target.value)

  }
  const onSearchClearAllHander = () => {
    setSearchText('')
  }

  return (
    <div>
      <div className="container-fluid">
        <div className={classNames('white-container', styles['white-container'])}>
          <div className="row">
            <div className="col-12 col-lg-8">
              <Stack direction="row" justifyContent="flex-start" alignItems="flex-end" gap={4}>
                <h1 className="pageHead mb-0">Contact List</h1>
                <Search placeholder="Enter to Search contacts"
                  onChange={onSearchChangeHander}
                  onClearAll={onSearchClearAllHander}
                  searchText={searchText}
                />
              </Stack>
            </div>
            <div className="col-12 col-lg-4 d-flex align-items-center justify-content-end ">
              {/* <p className="m-0 pe-3">If not found</p> */}
              <Button variant="contained" onClick={addCustomerBtnClick}>
                Add Contact
              </Button>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12">
              <div className="mt-2"><GanttExample />
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-12">
              <div className="mt-2">
                <TableWrapper
                  searchPlaceholder="Enter Contact Email"
                  tableTypeKey={CONTACT_LIST}
                  onChange={onChange}
                  apiList={gridData}
                  editAction={{
                    enableEditingMode,
                    enableEdit: editCell
                  }}
                  showSwimLane={true}
                  showChart={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
