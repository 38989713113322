import React from "react";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from '../../assets/images/icon-home.svg';
import routes from '../../constants/routeConfig';
import "./Breadcrumbs.scss";

// Helper function to capitalize the first letter
const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const Breadcrumbs = () => {
  const location = useLocation();

  // Remove any trailing dynamic ID if the path includes "edit" or "view"
  const basePath = location.pathname.includes("edit") || location.pathname.includes("view")
    ? location.pathname.split('/').slice(0, -1).join('/')
    : location.pathname;

  // Find the matching route based on the path
  const currentRoute = routes.find((route) =>
    basePath === route.path || (basePath.startsWith(route.path.replace(':id', '')) && route.path.includes(':id'))
  );

  const breadcrumbTextList = currentRoute?.breadcrumbText?.split("/") || [];
  const pathSegments = basePath.split("/");

  // Build crumbs array with capitalized names based on the current path
  const crumbs = pathSegments.map((segment, index) => {
    const path = pathSegments.slice(0, index + 1).join("/") || "/";
    const name = breadcrumbTextList[index] ? capitalizeFirstLetter(breadcrumbTextList[index]) : capitalizeFirstLetter(segment);
    return { name, path };
  });

  // Prevent rendering if there's only a single breadcrumb
  if (crumbs.length <= 1) return null;

  return (
    <div className="container-fluid">
      <div className="breadcrumbText">
        {/* Home icon as the starting breadcrumb */}
        <Link to="/" className="btn-link-icon">
          <img src={HomeIcon} alt="Home Icon" />
        </Link>
        
        {/* Render each breadcrumb link or plain text */}
        {crumbs.map(({ name, path }, index) => {
          const isLast = index === crumbs.length - 1;
          return isLast ? (
            <span key={index} className="breadcrumb-text">
              {" / " + name}
            </span>
          ) : (
            <React.Fragment key={index}>
              <Link className="btn-link" to={path}>
                {index > 0 && " / "}
                {name}
              </Link>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Breadcrumbs;
