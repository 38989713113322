import React from 'react';
import AddContact from '../pages/Contact/AddContact/AddContact';
import Contact from '../pages/Contact/Contact';
import ContactList from '../pages/Contact/ContactList/ContactList';
import EditProducts from '../pages/Masters/Products/EditProducts';
import EditServices from '../pages/Masters/Services/EditServices';
import Services from '../pages/Masters/Services/Services';
import ServicesList from '../pages/Masters/Services/ServicesList/ServicesList';
import SettingsLayout from '../pages/Settings/SettingsLayout';

const Home = React.lazy(() => import('../pages/Home'));
const NotFound = React.lazy(() => import('../pages/NotFound'));
const SalesProposal = React.lazy(() => import('../pages/SalesProposal/SalesProposal'));
const CreateSalesProposal = React.lazy(() => import('../pages/SalesProposal/creacte/CreateSalesProposal'));
const SalesProposalSummary = React.lazy(() => import('../pages/SalesProposal/Summary/SalesProposalSummary'));

const Estimate = React.lazy(() => import('../pages/Estimate/Estimate'));
const EstimateList = React.lazy(() => import('../pages/Estimate/EstimateList/EstimateList'));
const PreviewEstimate = React.lazy(() => import('../pages/Estimate/PreviewEstimate/PreviewEstimate'));
const CustomizeCreateEstimate = React.lazy(() => import('../pages/Estimate/CustomizeCreateEstimate/CustomizeCreateEstimate'));

const JobList = React.lazy(() => import('../pages/Job/JobList/JobList'));
const EditJob = React.lazy(() => import('../pages/Job/EditJob/EditJob'));
const CreateJob = React.lazy(() => import('../pages/Job/CreateJob/CreateJob'));

const Customer = React.lazy(() => import('../pages/Customer/Customer'));
const AddCustomer = React.lazy(() => import('../pages/Customer/AddCustomer/AddCustomer'));
const CustimerList = React.lazy(() => import('../pages/Customer/CustomerList/CustomerList'));

const Products = React.lazy(() => import('../pages/Masters/Products/Products'));
const ProductList = React.lazy(() => import('../pages/Masters/Products/ProductsList/ProductsList'));

const Deal = React.lazy(() => import('../pages/Masters/Deal/Deal'));
const DealList = React.lazy(() => import('../pages/Masters/Deal/DealList/DealList'));
// const AddDeal = React.lazy(() => import('../pages/Masters/Deal/AddDeal/AddDeal'));

const Projects = React.lazy(() => import('../pages/Worx/Projects/Projects'));
const ProjectsList = React.lazy(() => import('../pages/Worx/Projects/ProjectList/ProjectList'));
const ProjectsView = React.lazy(() => import('../pages/Worx/Projects/ProjectsView/ProjectsView'));

const Task = React.lazy(() => import('../pages/Worx/Tasks/Tasks'));
const TaskList = React.lazy(() => import('../pages/Worx/Tasks/TaskList/TaskList'));
const EditTask = React.lazy(()=> import ('../pages/Worx/Tasks/UpdateTask'))


const AddExpenses = React.lazy(() => import('../pages/Expenses/AddExpenses/AddExpenses'));
const ExpensesList = React.lazy(() => import('../pages/Expenses/ExpensesList/ExpensesList'));

const AddTicket = React.lazy(() => import('../pages/Tickets/AddTickets/AddTickets'));
const TicketList = React.lazy(() => import('../pages/Tickets/TicketList/TicketList'));

const Xmail = React.lazy(() => import('../pages/Xmail/Xmail'));
// const User = React.lazy(() => import('../pages/Settings/User'));
const Job = React.lazy(() => import('../pages/Settings/JobComponent/JobComponent'));
const Login = React.lazy(() => import('../pages/Login/Login'));
// const Estimate = React.lazy(() => import('../pages/Settings/Estimate'));
const general = React.lazy(() => import('../pages/Settings/GeneralComponent/GeneralComponent'));
const pipeline = React.lazy(() => import('../pages/Settings/PipelineComponent/PipelineComponent'));
const product = React.lazy(() => import('../pages/Settings/ProductComponent/ProductComponent'));
const deal = React.lazy(() => import('../pages/Settings/DealComponent/DealComponent'));
const TwoFactor = React.lazy(() => import('../pages/Settings/TwoFactorAuth/TwoFactorAuth'));
const UserVerify = React.lazy(() => import('../pages/Settings/UserVerificationComponent/UserVerificationComponent'));
const BusinessHours = React.lazy(() => import('../pages/Settings/BusinessHoursComponent/BusinessHoursComponent'));
const ProjectComponent = React.lazy(()=>import('../pages/Settings/ProjectComponent/ProjectSettings'))
const UserComponent = React.lazy(()=>import('../pages/Settings/CreateUser/CreateUser'))
//NOTE : breadcrumbText property should have same hierarchy as path property to display Custom Text

const routes = [
  { path: '/', name: 'Home', Component: Home, breadcrumbText: '/' },
  { path: "/sales/sales-proposal", name: "SalesProposal", Component: SalesProposal, breadcrumbText: '/Sales WorX/Sales Proposal' },
  { path: "/sales/sales-proposal/create", name: "CreateSalesProposal", Component: CreateSalesProposal, breadcrumbText: '/Sales WorX/Sales Proposal/Create' },
  { path: "/sales/sales-proposal/summary", name: "SalesProposalSummary", Component: SalesProposalSummary, breadcrumbText: '/Sales WorX/Sales Proposal/Summary' },

  { path: "/sales/estimate", name: "Estimate", Component: Estimate, breadcrumbText: '/Sales WorX/Estimates/' },
  { path: "/sales/estimate/list", name: "Estimate", Component: EstimateList, breadcrumbText: '/Sales WorX/Estimates/List', showOnDashboard: true },
  { path: "/sales/estimate/edit/:id", name: "CreateEstimate", Component: Estimate, breadcrumbText: '/Sales WorX/Estimates/Create' },
  { path: "/sales/estimate/create", name: "CreateEstimate", Component: Estimate, breadcrumbText: '/Sales WorX/Estimates/Create' },
  { path: "/sales/estimate/preview", name: "PreviewEstimate", Component: PreviewEstimate, breadcrumbText: '/Sales WorX/Estimate/Preview' },
  { path: "/sales/estimate/customize", name: "CustomizeCreateEstimate", Component: CustomizeCreateEstimate, breadcrumbText: '/Sales WorX/Estimate/Customize Estimate Form' },

  { path: '/sales/job/Edit', name: 'EditJob', Component: EditJob, breadcrumbText: '/Sales WorX/Job/Edit' },
  { path: '/sales/job/Create', name: 'CreateJob', Component: CreateJob, breadcrumbText: '/Sales WorX/Job/Create' },
  { path: '/sales/job/list', name: 'Job', Component: JobList, breadcrumbText: '/Sales WorX/Job/List', showOnDashboard: true },

  { path: '/customer', name: 'Customer', Component: Customer, breadcrumbText: '/Customer' },
  { path: '/customer/add', name: 'Customer', Component: AddCustomer, breadcrumbText: '/Customer/Add' },
  { path: '/customer/edit/:id', name: 'Customer', Component: AddCustomer, breadcrumbText: '/Customer/Add' },
  { path: '/customer/list', name: 'Customer', Component: CustimerList, breadcrumbText: '/Customer/List', showOnDashboard: true },

  { path: '/contact', name: 'Contact', Component: Contact, breadcrumbText: '/CRM WorX/Contact' },
  { path: '/contact/add', name: 'Contact', Component: Contact, breadcrumbText: '/CRM WorX/Contact/Add' },
  { path: '/contact/edit/:id', name: 'Contact', Component: AddContact, breadcrumbText: '/CRM WorX/Contact/Edit' },
  { path: '/contact/list', name: 'Contact', Component: ContactList, breadcrumbText: '/CRM WorX/Contact/List', showOnDashboard: true },

  { path: '/masters/products', name: 'Products', Component: ProductList, breadcrumbText: '/Price Book/Products' },
  { path: '/masters/products/list', name: 'Product', Component: ProductList, breadcrumbText: '/Price Book/Products/List', showOnDashboard: true },
  { path: '/masters/products/add', name: 'AddProduct', Component: Products, breadcrumbText: '/Price Book/Products/Add' },
  { path: '/masters/products/edit/:id', name: 'EditProduct', Component: EditProducts, breadcrumbText: '/Price Book/Products/Edit' },

  { path: '/masters/services', name: 'Services', Component: ServicesList, breadcrumbText: '/Price Book/Services' },
  { path: '/masters/services/list', name: 'Services', Component: ServicesList, breadcrumbText: '/Price Book/Services/List', showOnDashboard: true },
  { path: '/masters/services/add', name: 'AddServices', Component: Services, breadcrumbText: '/Price Book/Services/Add' },
  { path: '/masters/services/edit/:id', name: 'EditServices', Component: EditServices, breadcrumbText: '/Price Book/Services/Edit' },

  { path: '/crm/deal', name: 'Deal', Component: Deal, breadcrumbText: '/CRM WorX/Deal/Add' },
  { path: '/crm/deal/list', name: 'Deal', Component: DealList, breadcrumbText: '/CRM WorX/Deal/List', showOnDashboard: true },
  { path: '/crm/deal/add', name: 'AddDeal', Component: Deal, breadcrumbText: '/CRM WorX/Deal/Add' },

  {path: '/crm/deal/edit/:id', name: 'EditDeal', Component: Deal, breadcrumbText: '/CRM WorX/Deal/Edit'},
  
  
  { path: '/masters/ticket/list', name: 'Ticket', Component: TicketList, breadcrumbText: '/CRM WorX/Ticket/List', showOnDashboard: true },
  { path: '/masters/ticket/add', name: 'AddTicket', Component: AddTicket, breadcrumbText: '/CRM WorX/Ticket/Add' },

  { path: '/worx/projects', name: 'Projects', Component: ProjectsList, breadcrumbText: '/Project WorX/ProjectList' },
  { path: '/worx/projects/list', name: 'Projects', Component: ProjectsList, breadcrumbText: '/Project WorX/Project/List', showOnDashboard: true },
  { path: '/worx/projects/add', name: 'AddProject', Component: Projects, breadcrumbText: '/Project WorX/Project/Add' },
  { path: '/worx/projects/view/:id', name: 'ProjectsView', Component: ProjectsView, breadcrumbText: '/Project WorX/Project/View' },
  { path: '/worx/projects/edit/:id', name: 'ProjectsView', Component: Projects, breadcrumbText: '/Project WorX/Project/Edit' },

  { path: '/worx/task', name: 'Task', Component: TaskList, breadcrumbText: '/CRM WorX/Task' },
  { path: '/worx/task/list', name: 'Task', Component: TaskList, breadcrumbText: '/CRM WorX/Task/List', showOnDashboard: true },
  { path: '/worx/task/add', name: 'Task', Component: Task, breadcrumbText: '/CRM WorX/Task' },
  { path: '/worx/task/edit/:id', name: 'EditTask', Component: EditTask, breadcrumbText: '/CRM WorX/Task/Edit' },

  { path: '/sales/expenses/add', name: 'AddExpenses', Component: AddExpenses, breadcrumbText: '/Sales WorX/Expenses/Add' },
  { path: '/sales/expenses/list', name: 'Expenses', Component: ExpensesList, breadcrumbText: '/Sales WorX/Expenses/List', showOnDashboard: true },

  { path: '/xmail', name: 'xmail', Component: Xmail, breadcrumbText: '/Xmail' },
  
  {
    path: '/settings',
    name: 'Settings',
    Component: SettingsLayout,
    breadcrumbText: '/Settings',
    children: [
      { path: '/settings/general/businessname', name: 'General', Component: general, },
      { path: '/settings/general/twofa', name: 'TwoFactorAuth', Component: TwoFactor, },
      { path: '/settings/general/UserVerification', name: 'UserVerification', Component: UserVerify,  },
      { path: '/settings/general/businesshours', name:'BusinessHours', Component:BusinessHours},
      { path: '/settings/pipeline', name: 'Pipeline', Component: pipeline, },
      { path: '/settings/user', name:'User', Component:UserComponent},
      { path: '/settings/crm/deal', name: 'Deal', Component: deal,  },
      { path: '/settings/sales/job', name: 'Job', Component: Job,  },
      { path: '/settings/product', name: 'Product', Component: product,  },
      { path: '/settings/project/', name:'Project', Component:ProjectComponent},
    ],
  },
  { path: '*', name: 'NotFound', Component: NotFound, breadcrumbText: '*' },
];

export default routes;
