import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";

let tasks = [
  {
    start: new Date(2024, 1, 1),
    end: new Date(2024, 6, 6),
    name: 'Project 1',
    id: 'Task 1',
    type: 'task',
    progress: 45,
    isDisabled: true,
    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
  },
  {
    start: new Date(2024, 6, 6),
    end: new Date(),
    name: 'Project 2',
    id: 'Task 1',
    type: 'task',
    progress: 45,
    isDisabled: true,
    styles: { progressColor: '#ffee54', progressSelectedColor: '#ff6a0d' },
  },
];
export const GanttChart = () => <Gantt tasks={tasks} />