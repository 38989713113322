import React, { useRef } from 'react';
import { ACTION, ASC, CHECKBOX, DESC } from '../../constants/keyConstants';
import { useDispatch, useSelector } from 'react-redux';
import ListCheckbox from '../CustomCheckbox/ListCheckbox';
import { sortColumn } from '../../features/table/tableSlice';

const TableHeaderCell = ({ headerConfig, onChange }) => {
  const dispatch = useDispatch()
  const { selectedIds, gridData, sort } = useSelector(state => state.table)
  const {
    type,
    columnName,
    columnKey,
    cellStyle,
    isSortable,
    isDraggable
  } = headerConfig;
  const headerRef = useRef(null);

  const handleMouseDown = (e) => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (headerRef.current) {
      const newWidth = e.clientX - headerRef.current.getBoundingClientRect().left;
      headerRef.current.style.width = `${newWidth}px`;
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  switch (type) {
    case CHECKBOX: return <th style={cellStyle}>
      <ListCheckbox
        onChange={(event) => onChange(event)}
        checked={gridData?.length > 0 ? selectedIds?.length === gridData?.length : false}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
        disabled={gridData?.length === 0}
      /></th>;
    case ACTION:
      return <th style={cellStyle}>
        {/* <AppsIcon
          sx={{ fontSize: 25 }}
        /> */}
      </th>
    default:
      return <th ref={headerRef} style={{ position: 'relative', ...headerConfig.cellStyle }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isDraggable && <span className="dragCol" ></span>}{columnName}
          {isSortable && <span onClick={() => {
            dispatch(sortColumn({ columnKey, sortDir: sort?.sortDir === ASC ? DESC : ASC }))
          }}
            className={sort?.sortDir === ASC && columnKey === sort.columnKey ? "orderChangeAce" : "orderChangeDec"} >
          </span>}
        </div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            width: '5px',
            cursor: 'col-resize'
          }}
          onMouseDown={handleMouseDown}
        />
      </th>
  }
};

export default TableHeaderCell;
