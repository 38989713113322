import { Button, IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

import tableConfig from '../../components/Table/tableConfig';

import { ACTION, CHECKBOX, labelMap } from '../../constants/keyConstants';

import AssessmentIcon from '@mui/icons-material/Assessment';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import LoadingComponent from '../../common/LoadingComponent/LoadingComponent';
import Board from '../../features/table/components/Board';
import { initializeColumnsInGrid, updatePagination } from '../../features/table/tableSlice';
import { GanttChart } from '../../pages/Contact/GanttChartComponent';
import SelectMenu from '../SelectMenu/SelectMenu';
import VisibilityToggleMenu from '../VisibilityToggleMenu/VisibilityToggleMenu';
import ColumnCustomization from './ColumnCustomization';
import TablePagination from './TablePagination';
import './TableWrapper.scss';


const TableWrapper = (props) => {
  const {
    tableTypeKey,
    apiList,
    handleClick,
    onChange,
    value,
    editAction,
    showSwimLane = false,
    actionHandlers,
    isMainTable = true,
    showChart = false
  } = props;

  const dispatch = useDispatch()


  const [headersList, setHeaderList] = useState(tableConfig(tableTypeKey))
  const [swimlaneDropdownValue, setSwimLaneDropdownValue] = useState("")
  const [cardVisibilityList, setCardVisibilityList] = useState([])
  const { selectedIds,
    pagination,
    isLoading,
    leadDropdown,
    companyOwnerdropDownData
  } = useSelector(state => state.table)


  const [columnList, setColumnList] = useState(leadDropdown && leadDropdown[0]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId })))
  const [viewSelectionValue, setViewSelectionValue] = useState(0);
  const [showColumns, setShowColumns] = useState([]);
  const [hideColumns, setHideColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);

  function reorderArray(arrayA, arrayB) {
    // Separate special columns (CHECKBOX and ACTION)
    const checkboxColumn = arrayB.find(col => col.type === CHECKBOX);
    const actionColumn = arrayB.find(col => col.type === ACTION);

    // Get main content columns (excluding checkbox and action)
    const contentColumns = arrayB.filter(col =>
      col.type !== CHECKBOX && col.type !== ACTION
    );

    // Create a mapping of column names to their objects
    const columnMap = new Map(
      contentColumns.map(col => [col.columnName, col])
    );

    // Reorder content columns based on Array A
    const reorderedContent = arrayA.map(columnName => {
      const column = columnMap.get(columnName);
      if (column) {
        return column;
      }
    });

    // Combine special columns with reordered content
    const result = [];
    if (checkboxColumn) result.push(checkboxColumn);
    result.push(...reorderedContent);
    if (actionColumn) result.push(actionColumn);

    // Update columnIndex values to reflect new order
    return result.map((col, index) => ({
      ...col,
      columnIndex: index
    }));
  }

  // Function to finalize column selection
  const finalizeColumnSelection = (updatedShowColumns, updatedHideColumns) => {
    setShowColumns(updatedShowColumns);
    setHideColumns(updatedHideColumns);
    console.log(updatedShowColumns, updatedHideColumns);
    const updatedConfig = reorderArray(updatedShowColumns, tableConfig(tableTypeKey)?.filter(col => updatedShowColumns.includes(col.columnName) || !col?.columnKey));
    setHeaderList(updatedConfig);
    console.log(updatedConfig);
    setShowModal(false);
  };

  // Initialize show and hide columns from table config
  useEffect(() => {
    const config = tableConfig(tableTypeKey);
    const shown = config.filter(col => col.columnKey).map(col => col.columnName);
    const hidden = config.filter(col => !col.isDraggable && col.columnKey).map(col => col.columnName);
    setShowColumns(shown);
    setHideColumns(hidden);
  }, [tableTypeKey]);



  const handleChange = (newValue) => {
    setViewSelectionValue(newValue);
    setColumnList(leadDropdown && leadDropdown[0]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId, task: apiList?.filter((eachRow) => eachRow.contactStageName === owner?.value) })))
  };

  const disabled = selectedIds?.length === 0;

  const handlePageChange = (event, value) => {
    dispatch(updatePagination({
      perPage: pagination?.perPage,
      page: value
    }))
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    dispatch(updatePagination({
      perPage: newRowsPerPage,
      page: newRowsPerPage === pagination?.perPage ? pagination?.page : 1,
    }));
  };

  useEffect(() => {
    if (isMainTable) {
      dispatch(initializeColumnsInGrid({ tableType: tableTypeKey }));
    }
  }, [])


  const swimlaneDropdownList = [
    { label: "LifeCycle Stage", value: "LifeCycle Stage" },
    { label: "Company Owner", value: "Company Owner" },
    { label: "Lead Status", value: "Lead Status" }
  ];

  useEffect(() => {
    setSwimLaneDropdownValue("LifeCycle Stage")
  }, [])

  useEffect(() => {
    if (swimlaneDropdownValue === "LifeCycle Stage") {
      setColumnList(leadDropdown && leadDropdown[0]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId, task: apiList?.filter((eachRow) => eachRow.contactStageName === owner?.value) })))
    }
    else if (swimlaneDropdownValue === "Lead Status") { setColumnList(leadDropdown && leadDropdown[1]?.values?.map((owner) => ({ ...owner, label: owner?.value, value: owner?.optionValueId, task: apiList?.filter((eachRow) => eachRow.contactStatusName === owner?.value) }))) }
    else { setColumnList(companyOwnerdropDownData?.map((owner) => ({ ...owner, label: owner?.optionShow, value: owner?.employeeId, task: apiList?.filter((eachRow) => eachRow.contactOwnerName === owner?.name) }))) }
  }, [swimlaneDropdownValue, leadDropdown, companyOwnerdropDownData])


  const cardItems = Object.keys(labelMap);

  return (
    <div className="row">
      {isLoading ? <LoadingComponent /> : <>
        <div className="col-12 col-lg-4 d-flex" style={{ gap: 4, alignItems: 'center' }} >
          {<IconButton onClick={() => handleChange(0)} className={viewSelectionValue === 0 ? 'selected-button' : 'unselected-button'} title='Grid View'><GridViewIcon fontSize='small' /> </IconButton>}
          {showSwimLane && <IconButton onClick={() => handleChange(1)} className={viewSelectionValue === 1 ? 'selected-button' : 'unselected-button'} title='Swimlane View'><CalendarViewWeekIcon fontSize='small' /> </IconButton>}
          {showChart && <IconButton onClick={() => handleChange(2)} className={viewSelectionValue === 2 ? 'selected-button' : 'unselected-button'} title='Chart View'><AssessmentIcon fontSize='small' /> </IconButton>}
          <p className='mt-3 ml-3 fs-14'>Total : <span className=' fw-bold'>{pagination?.totalRecords || apiList?.length}</span> {selectedIds?.length > 0 && <i className=''>Selected : <span className='fw-bold text-primary'>{selectedIds?.length}</span></i>}</p>
        </div>
        <div className='col-12 col-lg-8 d-flex align-items-end justify-content-end' >
          {viewSelectionValue === 0 && (
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              {apiList?.length > 0 && selectedIds?.length === apiList?.length && <Button label="" variant="text">Email All</Button>}
              <Button variant='text' disabled={disabled}><DeleteOutlinedIcon fontSize='small' />Delete</Button>
              <Button variant='text' disabled={disabled}><EditOutlinedIcon fontSize='small' />Edit</Button>
              <Button variant='text' disabled={disabled}><ExitToAppOutlinedIcon fontSize='small' />Export</Button>
              <Button variant='text' fontSize='small' onClick={() => setShowModal(true)}>Edit Columns</Button>
              <ColumnCustomization
                tableType={tableTypeKey}
                showModal={showModal} setShowModal={setShowModal}
                showColumns={showColumns} setShowColumns={setShowColumns}
                hideColumns={hideColumns} setHideColumns={setHideColumns}
                finalizeColumnSelection={finalizeColumnSelection} />
            </Stack>
          )}
          {viewSelectionValue === 1 && (
            <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="flex-end">
              <div className='filterSelect'>
                <SelectMenu
                  // selectLabel="Filter "
                  value={swimlaneDropdownValue}
                  options={swimlaneDropdownList}
                  onChangeCallback={(selectedValue) => setSwimLaneDropdownValue(selectedValue)}
                />
              </div>
              <div>
                <VisibilityToggleMenu items={cardItems} setCardVisibilityList={setCardVisibilityList} />
              </div>

            </Stack>
          )}
        </div>
        <div className='col-12' style={{ padding: 0 }}>
          {viewSelectionValue === 0 && (
            <div className='tableContainer  ' >
              <table className="table table-hover ">
                <TableHeader
                  headers={headersList}
                  records={apiList}
                  onChange={onChange}
                />
                <TableBody
                  headers={headersList}
                  records={apiList}
                  handleClick={handleClick}
                  onChange={onChange}
                  value={value}
                  editAction={editAction}
                  actionHandlers={actionHandlers}
                />
              </table>
              <div className="col-12" style={{ padding: 0 }}>
                <TablePagination
                  pagination={pagination}
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  length={apiList?.length}
                />
              </div>
            </div>
          )}
          {viewSelectionValue === 1 && (
            <div><Board columnList={columnList} taskList={cardVisibilityList} /></div>
          )}
          {viewSelectionValue === 2 && (
            <div><GanttChart /></div>
          )}
        </div>
      </>}
    </div>
  );
};
export default TableWrapper;
