import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { orderBy } from 'lodash';
import { getRequest, postRequest } from '../../api/axiosConfig';
import tableConfig from '../../components/Table/tableConfig';
import { ASC } from '../../constants/keyConstants';

const initialState = {
    isLoading: false,
    gridData: [], //TODO : Replace with api going further
    selectedIds: [],
    pagination: {
        count: 0,
        page: 1,
        totalRecords: 0,
        perPage: 10,
    },
    selectedColumnsInGrid: [], //TODO : Replace runtime going further
    sort: { columnName: "", sortDir: ASC },
    createEstimateData: {},
    projectMasterDetails: {},
}
export const fetchGridData = createAsyncThunk(
    'table/getData',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchDropDownData = createAsyncThunk(
    'common/getDropdownData',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchCategoryDropDown = createAsyncThunk(
    'common/getCategoryDropdown',
    async ({ endpointURL, objectKey },) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchDepartmentDropDown = createAsyncThunk(
    'common/getDepartmentDropdown',
    async ({ endpointURL, objectKey },) => {
        console.log("objectKey", objectKey)
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchStatusDropDown = createAsyncThunk(
    'common/getDropdown',
    async ({ endpointURL, objectKey },) => {
        console.log("objectKey", objectKey)
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchJobListDetails = createAsyncThunk(
    'estimate/getJobListDetails',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchCustomerInfo = createAsyncThunk(
    'estimate/getCustomerInfo',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchEmployeeList = createAsyncThunk(
    'estimate/getEmployeeList',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchProductsList = createAsyncThunk(
    'estimate/getProductsList',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchProductDetails = createAsyncThunk(
    'estimate/getProductDetails',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })

export const postCreateEstimate = createAsyncThunk(
    'estimate/updateCreateEstimate',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, { ...payload });
        return response;
    })

export const fetchEstimateDetails = createAsyncThunk(
    'estimate/getEstimateDetails',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })

export const saveEstimate = createAsyncThunk(
    'estimate/saveEstimate',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, payload);
        return response;
    })

export const fetchWarehouseDropdown = createAsyncThunk(
    'estimate/getWarehouseDropdown',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })

export const fetchStatusList = createAsyncThunk(
    'common/getAllStatusTypes',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })


export const updateCellStatus = createAsyncThunk(
    'common/updateCellStatus',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, payload);
        return response.data.result;
    })


/**Customer Api starts */
export const postCreateCustomer = createAsyncThunk(
    'customer/updateCreateCustomer',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, { ...payload });
        return response;
    })

/**
 * Contact Endpoints
 */
export const fetchContactList = createAsyncThunk(
    'contact/getContactList',
    async ({ endpointURL, searchValue }) => {
        if (!searchValue) {
            const response = await getRequest(endpointURL);
            return response.data.result;
        }
        else {
            let newURL = `${endpointURL}&search=${searchValue}`;
            const response = await getRequest(newURL);
            return response.data.result;
        }
    })

export const postCreateContact = createAsyncThunk(
    'contact/updateCreateContact',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, { ...payload });
        return response;
    })

export const updateProductDetails = createAsyncThunk(
    'contact/updateProductDetails',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, { ...payload });
        return response;
    })

export const fetchContactDetails = createAsyncThunk(
    'contact/getContactDetails',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchContactCompanyOwnersList = createAsyncThunk(
    'contact/getContactCompanyOwnersList',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchLeadStatusDropdown = createAsyncThunk(
    'contact/getLeadStatusDropdown',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })

export const postAddNotes = createAsyncThunk(
    'contact/updateNotes',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, { ...payload });
        return response;
    })
export const getContactNotes = createAsyncThunk(
    'contact/getContactNotes',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
//Product master page

export const fetchProductMasterDetails = createAsyncThunk(
    'product/getProductMasterDetails',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchProductMasterImageDetails = createAsyncThunk(
    'product/fetchProductMasterImageDetails',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchCategoryDropdown = createAsyncThunk(
    'product/getCategoryDropdown',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchSubCategoryDropdown = createAsyncThunk(
    'product/getSubCategoryDropdown',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchSubCategoryDropdownById = createAsyncThunk(
    'product/getSubCategoryDropdownById',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchClassofProductDropdown = createAsyncThunk(
    'product/getClassofProductDropdown',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchMeasureOfUnitDropdown = createAsyncThunk(
    'product/getMeasureOfUnitDropdown',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const postSubCategoryDropdown = createAsyncThunk(
    'product/postSubCategoryDropdown',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, { ...payload });
        return response;
    })
export const postClassOfProductDropdown = createAsyncThunk(
    'product/postClassofProduct',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, { ...payload });
        return response;
    })
export const postMeasureOfUnitDropdown = createAsyncThunk(
    'product/postMeasureOfUnit',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, { ...payload });
        return response;
    })

export const uploadFiles = createAsyncThunk(
    'product/uploadFiles',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, payload);
        return response;
    })

//Project Master

export const fetchProjectMasterDetails = createAsyncThunk(
    'project/getProjectMasterDetails',
    async (endpointURL) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchContactDropDown = createAsyncThunk(
    'project/getContactDropdown',
    async ({ endpointURL, objectKey },) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchMembersDropdown = createAsyncThunk(
    'project/getMemberDropdown',
    async ({ endpointURL },) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })
export const fetchSubtaskDropdown = createAsyncThunk(
    'task/getSubtaskDropdown',
    async ({ endpointURL },) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    })

export const fetchSubTaskList = createAsyncThunk(
    'project/fetchSubTaskList',
    async ({ endpointURL }) => {
        const response = await getRequest(endpointURL);
        return response.data.result;
    }
)

export const createCustomization = createAsyncThunk(
    'product/createCustomization',
    async ({ endpointURL, payload }) => {
        const response = await postRequest(endpointURL, payload);
        return response.data.result;
    }
)
export const tableSlice = createSlice({
    name: "table",
    initialState,
    reducers: {
        initializeColumnsInGrid: (state, { payload }) => {
            const { tableType } = payload;
            state.selectedColumnsInGrid = tableConfig(tableType)?.map(eachColumn => eachColumn?.columnName);
        },
        searchGridData: (state, { payload }) => {
            const { searchText, columnKey } = payload;
            const allRecords = [...state.gridData];
            state.gridData = allRecords?.filter(data => data[columnKey]?.toLowerCase()?.includes(searchText?.toLowerCase()));
        },
        sortColumn: (state, { payload }) => {
            state.sort = payload;
            state.selectedIds = [];
            const { columnKey, sortDir } = payload;
            state.gridData = orderBy(state.gridData, [columnKey], [sortDir]);
        },
        handleGridColumns: (state, { payload }) => {

            const { isChecked, selectedColumnName } = payload;
            if (isChecked) {
                state.selectedColumnsInGrid = [...state.selectedColumnsInGrid, selectedColumnName];
            } else {
                state.selectedColumnsInGrid = state.selectedColumnsInGrid?.length === 3 ? state.selectedColumnsInGrid : state.selectedColumnsInGrid?.filter((columnItem) => columnItem !== selectedColumnName); // NOTE : Here === 3 signifies (including a checkbox column) that last element can't be removed)
            }
        },
        handleDataSelection: (state, { payload }) => {
            const { selectedRecordId, allSelected, actionType } = payload;
            if (allSelected) {
                state.selectedIds = actionType ? state.gridData?.map(allRecords => allRecords.id) : [];
            } else {
                state.selectedIds = actionType ? [...state.selectedIds, selectedRecordId] : state.selectedIds?.filter((eachId) => eachId !== selectedRecordId);
            }
        },
        resetCheckboxSelection: (state) => {
            state.selectedIds = [];
        },
        resetTable: (state) => {
            state.selectedIds = [];
            state.gridData = [];
        },
        updatePagination: (state, { payload }) => {
            console.log("payload in pagination", payload)
            state.pagination.page = payload.page;
            state.pagination.perPage = payload.perPage;
        },
        handleEditorText: (state, { payload }) => {
            if (payload?.description)
                state.productMasterDetails.description = payload?.description;
        },
        handleEditorTextSummary: (state, { payload }) => {

            state.projectMasterDetails["description"] = payload?.description;
        },
        handleEditorTextNotes: (state, { payload }) => {
            state.projectMasterDetails["notes"] = payload?.notes;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGridData.pending, (state, action) => {
            state.isLoading = true;
            state.selectedIds = [];
            state.gridData = [];
        });
        builder.addCase(fetchGridData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.gridData = action.payload;
            state.pagination.page = action.payload.pageSetup?.currentPage;
            state.pagination.perPage = action.payload.pageSetup?.perPage;
            state.pagination.count = action.payload.pageSetup?.lastPage;
            state.pagination.totalRecords = action.payload.pageSetup?.totalRecord;
        });
        builder.addCase(fetchGridData.rejected, (state, action) => {
            state.isLoading = false;
            state.selectedIds = [];
            state.error_msg = action.payload;
        });
        builder.addCase(fetchDropDownData.pending, (state, action) => {
            state.dropDownData = [];
        });
        builder.addCase(fetchDropDownData.fulfilled, (state, action) => {
            state.dropDownData = action.payload;
        });
        builder.addCase(fetchDropDownData.rejected, (state, action) => {
            state.error_msg = action.payload;
        });
        builder.addCase(fetchCustomerInfo.pending, (state, action) => {
            state.customerInfo = [];
        });
        builder.addCase(fetchCustomerInfo.fulfilled, (state, action) => {
            state.customerInfo = action.payload;
        });
        builder.addCase(fetchCustomerInfo.rejected, (state, action) => {
            state.error_msg = action.payload;
        });
        builder.addCase(fetchStatusList.pending, (state, action) => {
            state.statusList = [];
        });
        builder.addCase(fetchStatusList.fulfilled, (state, action) => {
            state.statusList = action.payload;
        });
        builder.addCase(fetchStatusList.rejected, (state, action) => {
            state.error_msg = action.payload;
        });
        builder.addCase(updateCellStatus.pending, (state, action) => {
        });
        builder.addCase(updateCellStatus.fulfilled, (state, action) => {
        });
        builder.addCase(updateCellStatus.rejected, (state, action) => {
            state.isLoading = false;
            state.error_msg = action.payload;
        });
        builder.addCase(fetchEmployeeList.fulfilled, (state, action) => {
            state.employeeList = action.payload;
        });
        builder.addCase(fetchProductsList.fulfilled, (state, action) => {
            state.productsList = action.payload;
        });
        builder.addCase(fetchProductDetails.fulfilled, (state, action) => {
            state.productDetails = action.payload;
        });
        builder.addCase(fetchEstimateDetails.pending, (state, action) => {
            state.isEstimateLoading = true;
        });
        builder.addCase(fetchEstimateDetails.rejected, (state, action) => {
            state.error_msg = action.payload;
            state.isEstimateLoading = false;
        });
        builder.addCase(fetchEstimateDetails.fulfilled, (state, action) => {
            state.isEstimateLoading = false;
            state.createEstimateData = action.payload;
        });
        builder.addCase(fetchWarehouseDropdown.fulfilled, (state, action) => {
            state.warehouseDropdown = action.payload;
        });
        builder.addCase(fetchJobListDetails.fulfilled, (state, action) => {
            state.jobList = action.payload;
        });
        builder.addCase(fetchContactDetails.pending, (state, action) => {
            state.contactInformation = null;
        });
        builder.addCase(fetchContactDetails.fulfilled, (state, action) => {
            state.contactInformation = action.payload;
        });
        builder.addCase(fetchProductMasterDetails.pending, (state, action) => {
            state.productMasterDetails = null;
        });
        builder.addCase(fetchProductMasterDetails.fulfilled, (state, action) => {
            state.productMasterDetails = action.payload;
            state.productMasterImageDetails = action.payload?.imageUrl;
        });
        builder.addCase(fetchProjectMasterDetails.pending, (state, action) => {
            state.projectMasterDetails = null;
            state.pagination.totalRecords = null;
        });
        builder.addCase(fetchProjectMasterDetails.fulfilled, (state, action) => {
            state.projectMasterDetails = action.payload;
            state.pagination.page = action.payload.pageSetup?.currentPage;
            state.pagination.perPage = action.payload.pageSetup?.perPage;
            state.pagination.count = action.payload.pageSetup?.lastPage;
            state.pagination.totalRecords = action.payload.pageSetup?.totalRecord;
        });
        builder.addCase(updateProductDetails.pending, (state, action) => {
            state.productMasterImageDetailsLoading = true;
        });
        builder.addCase(fetchProductMasterImageDetails.pending, (state, action) => {
            state.productMasterImageDetailsLoading = true;
        });
        builder.addCase(fetchProductMasterImageDetails.fulfilled, (state, action) => {
            state.productMasterImageDetailsLoading = false;
            state.productMasterImageDetails = action.payload?.imageUrl;
        });
        builder.addCase(fetchContactCompanyOwnersList.fulfilled, (state, action) => {
            state.companyOwnerdropDownData = action.payload;
        });
        builder.addCase(fetchLeadStatusDropdown.fulfilled, (state, action) => {
            state.leadDropdown = action.payload;
        });
        builder.addCase(fetchContactList.pending, (state, action) => {
            state.isLoading = true;
            state.gridData = [];
            state.selectedIds = [];
        });
        builder.addCase(fetchContactList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.gridData = action.payload?.list;
            state.menuList = action.payload?.menu;
            state.pagination.page = action.payload.pageSetup?.currentPage;
            state.pagination.perPage = action.payload.pageSetup?.perPage;
            state.pagination.count = action.payload.pageSetup?.lastPage;
            state.pagination.totalRecords = action.payload.pageSetup?.totalRecord;
        });
        builder.addCase(fetchSubTaskList.fulfilled, (state, action) => {
            state.subTaskListData = action.payload?.list;

        });

        builder.addCase(getContactNotes.fulfilled, (state, action) => {
            state.notes = action.payload;
        });
        builder.addCase(postAddNotes.fulfilled, (state, action) => {
            state.noteId = action.payload;
        });
        builder.addCase(fetchCategoryDropdown.fulfilled, (state, action) => {
            state.categoryList = action.payload;
        });
        builder.addCase(fetchSubCategoryDropdown.fulfilled, (state, action) => {
            state.subCategoryList = action.payload;
        });
        builder.addCase(postClassOfProductDropdown.fulfilled, (state, action) => {
            state.classOfProductListUpdated = true;
        });
        builder.addCase(postMeasureOfUnitDropdown.fulfilled, (state, action) => {
            state.measureOfUnitListUpdated = true;
        });
        builder.addCase(postSubCategoryDropdown.fulfilled, (state, action) => {
            state.isDropdownRefreshed = true;
        });
        builder.addCase(fetchClassofProductDropdown.fulfilled, (state, action) => {
            state.classOfProductList = action.payload;
        });
        builder.addCase(fetchMeasureOfUnitDropdown.fulfilled, (state, action) => {
            state.measureOfUnitList = action.payload;
        });
        builder.addCase(postCreateContact.fulfilled, (state, action) => {
            state.isDetailsRefreshed = true;
        });
        builder.addCase(fetchCategoryDropDown.fulfilled, (state, action) => {
            state.categoryDropdownList = action.payload;
        });
        builder.addCase(fetchDepartmentDropDown.fulfilled, (state, action) => {
            state.departmentDropdownList = action.payload;
        });
        builder.addCase(fetchStatusDropDown.fulfilled, (state, action) => {
            state.statusDropdownList = action.payload;
        });
        builder.addCase(fetchContactDropDown.fulfilled, (state, action) => {
            state.contactDropdownList = action.payload;
        });
        builder.addCase(fetchMembersDropdown.fulfilled, (state, action) => {
            state.membersDropdownList = action.payload;
        });
        builder.addCase(fetchSubtaskDropdown.fulfilled, (state, action) => {
            state.subTaskDropdownList = action.payload;
        });

    },
});


export const {
    initializeColumnsInGrid,
    searchGridData,
    sortColumn,
    handleGridColumns,
    handleDataSelection,
    resetCheckboxSelection,
    resetTable,
    updatePagination,
    handleEditorText,
    handleEditorTextSummary,

    handleEditorTextNotes
} = tableSlice.actions;

export default tableSlice.reducer;