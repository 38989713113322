import React, { useState } from 'react';
import SweetAlert from 'react-sweetalert2';

function Notifications(props) {
  const {
    open,
    message,
    color = "success",
    title = "",
    onClose,
    confirmationHandler = () => { },
    showConfirmButton = true,
    showCancelButton = false } = props;
  const [toggleOpen, setToggleOpen] = useState(open);

  const handleToClose = (event, reason) => {
    if ("clickaway" === reason) return;
    setToggleOpen(false);
    onClose()
  };
  const onSubmit = (event, reason) => {
    confirmationHandler()
    if ("clickaway" === reason) return;
    setToggleOpen(false);
    onClose()
  }

  const handleClickEvent = () => {
    setToggleOpen(true);
  };

  return (<SweetAlert
    show={toggleOpen}
    title={title}
    text={message}
    onConfirm={onSubmit}
    icon={color}
    // {...swalProps}
    // didOpen={() => {
    //   // run when swal is opened...
    // }}
    didClose={handleToClose}
    // onError={error => {
    //   // run when promise rejected...
    // }}
    // onResolve={result => {
    //   // run when promise is resolved...
    // }}
    showConfirmButton={showConfirmButton}
    showCancelButton={showCancelButton}
  />

  )
}

export default Notifications