import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import StarIcon from "@mui/icons-material/Star";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { __API_BASEURL__ } from '../../constants/keyConstants';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import "./CustomizationWrapper.scss";

function CustomizationWrapper({ categoryList, tabs = true }) {  // New `tabs` prop with default value `true`
    const [visible, setVisible] = useState(true);
    const [mandatory, setMandatory] = useState(true);
    const [loading, setLoading] = useState(false);
    const [categoryId, setCategoryId] = useState(null);
    const [CustomizationList, setCustomizationList] = useState([]);
    const company_id = localStorage.getItem("company_id");
    const user_id = localStorage.getItem("user_id");
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [expandedCategory, setExpandedCategory] = useState(null);
    const [value, setValue] = React.useState(0);  // State for tabs

    const visibilityHandleClick = () => {
        setVisible(!visible);
    };

    const mandatoryHandleClick = () => {
        setMandatory(!mandatory);
    };

    const handleCategoryClick = (category) => {
        setLoading(true);
        setSubCategoryList([]);
        setCategoryId(category.id);
        axios.get(`${__API_BASEURL__}/settings/subcategory/dropdown?company_id=${company_id}&user_id=${user_id}&categoryId=${category?.id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
            }
        }).then((response) => {
            setSubCategoryList(response.data.result);
            setLoading(false);
        });
        setExpandedCategory(expandedCategory?.label === category?.label ? null : category);
    };

    useEffect(() => {
        setCustomizationList(categoryList.map((eachItem) => (categoryId === eachItem?.id ? {
            label: eachItem.category,
            subCategories: subCategoryList,
            id: eachItem?.id
        } : {
            label: eachItem.category,
            subCategories: [],
            id: eachItem?.id
        })));
    }, [categoryId, subCategoryList, categoryList]);

    const CustomTabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='customization-wrapper'>
            <div className="row">
                <div className="col-12">
                    {/* Conditionally render Tabs based on `tabs` prop */}
                    {tabs && (
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: '16px' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="customization tabs" variant="scrollable" scrollButtons={false}>
                                <Tab label="Category" {...a11yProps(0)} />
                            </Tabs>
                        </Box>
                    )}
                    <CustomTabPanel value={value} index={0}>
                        <List>
                            {CustomizationList?.map((eachItem, index) => (
                                <React.Fragment key={index}>
                                    <ListItem disableGutters button onClick={() => handleCategoryClick(eachItem)} className='border-top'>
                                        <IconButton edge="start" disabled>
                                            <DragIndicatorIcon />
                                        </IconButton>
                                        <ListItemText primary={eachItem.label} />
                                        <div className='listHoverItems'>
                                            <div className="d-flex gap-2 " edge="end">
                                                <Button
                                                    onClick={visibilityHandleClick}
                                                    variant="text"
                                                    startIcon={visible ? (<RemoveRedEyeOutlinedIcon fontSize="small" />) : (<VisibilityOffOutlinedIcon fontSize="small" />)}
                                                >
                                                    {visible ? "Visible" : "Hidden"}
                                                </Button>
                                                <Button
                                                    onClick={mandatoryHandleClick}
                                                    variant="text"
                                                    startIcon={mandatory ? (<StarIcon fontSize="small" />) : (<StarBorderOutlinedIcon fontSize="small" />)}
                                                >
                                                    {mandatory ? "Mandatory" : "Optional"}
                                                </Button>
                                            </div>
                                            <div edge="end" className="d-flex gap-1">
                                                <IconButton>
                                                    <ModeEditOutlineOutlinedIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton>
                                                    <DeleteOutlineOutlinedIcon fontSize="small" />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <IconButton edge="start">
                                            {expandedCategory?.label === eachItem?.label ? (
                                                <ExpandLessOutlinedIcon fontSize="small" />
                                            ) : (
                                                <ExpandMoreOutlinedIcon fontSize="small" />
                                            )}
                                        </IconButton>
                                    </ListItem>
                                    {expandedCategory?.label === eachItem?.label && eachItem?.subCategories?.length > 0 && (
                                        <>{loading ? <LoadingComponent height="50px" /> : (
                                            <List component="div" disablePadding className='px-4 subCatList'>
                                                {eachItem.subCategories.map((subItem, subIndex) => (
                                                    <ListItem key={subIndex} disableGutters className='border-top'>
                                                        <ListItemText primary={subItem.subCategory} className='ps-3' />
                                                        <div className='listHoverItems'>
                                                            <Button
                                                                onClick={visibilityHandleClick}
                                                                variant="text"
                                                                startIcon={visible ? (<RemoveRedEyeOutlinedIcon fontSize="small" />) : (<VisibilityOffOutlinedIcon fontSize="small" />)}
                                                            >
                                                                {visible ? "Visible" : "Hidden"}
                                                            </Button>
                                                            <Button
                                                                onClick={mandatoryHandleClick}
                                                                variant="text"
                                                                startIcon={mandatory ? (<StarIcon fontSize="small" />) : (<StarBorderOutlinedIcon fontSize="small" />)}
                                                            >
                                                                {mandatory ? "Mandatory" : "Optional"}
                                                            </Button>
                                                            <IconButton>
                                                                <ModeEditOutlineOutlinedIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton>
                                                                <DeleteOutlineOutlinedIcon fontSize="small" />
                                                            </IconButton>
                                                        </div>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        )}</>
                                    )}
                                </React.Fragment>
                            ))}
                        </List>
                    </CustomTabPanel>
                </div>
            </div>
        </div>
    );
}

export default CustomizationWrapper;
