import axios from 'axios'
import { __API_BASEURL__ } from '../constants/keyConstants';


// TODO : Add localStorage getter/setters
// NOTE : token added for dev purpose

// const token = '1|AVFKH2PcsrljAoIT9ezscs6xEpJ0Kcyzj4yAkCm273254a71';
// localStorage.setItem("token", token)
// localStorage.setItem("company_id", 200000000)
// localStorage.setItem("user_id", 100000000)
// const axiosClient = axios.create({
//   baseURL: __API_BASEURL__,
//   headers: {
//     'Content-Type': 'application/json',
//     'Authorization': 'Bearer ' + token,
//     'X-CSRF-TOKEN': window.csrf_token,
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
//   }
// });

// axios.defaults.withCredentials = false;

// export default axiosClient;

const axiosClient = axios.create({
  baseURL: __API_BASEURL__,
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.log("in else interceptors token has expired", token, config)
      // localStorage.removeItem("token")
    }
    return config;
  },
  (error) => {
    console.log("Request Error Object in client", error)
    return Promise.reject(error);
  }
);

// Set up Axios interceptor to catch 401 responses
axiosClient.interceptors.response.use(
  response => response,  // Pass through success responses
  error => {
    console.log("Response Error Object in client", error)
    if (error.response && error.response.status === 401) {
      // Dispatch a global event for logout
      window.dispatchEvent(new Event('logout'));
    }
    return Promise.reject(error);
  }
);

export default axiosClient;

