import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { __API_BASEURL__, __AUTH_URL__ } from '../constants/keyConstants';
import axios from 'axios';
import LoadingComponent from '../common/LoadingComponent/LoadingComponent';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const validateToken = useCallback(async (tokenToUse) => {
        if (tokenToUse) {
            try {
                const response = await axios.post(
                    `${__API_BASEURL__}/create-bearer-token-from-redirect`,
                    { token: tokenToUse },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'token': tokenToUse,
                        }
                    }
                );

                if (response.data && response.data.result) {
                    const { token, company_id, user_id } = response.data.result;

                    // Store the valid token and user details
                    setUser({ token, company_id, user_id });
                    localStorage.setItem('token', token);
                    localStorage.setItem('company_id', company_id);
                    localStorage.setItem('user_id', user_id);

                    // Clear token from URL if present
                    if (window.location.search.includes('token')) {
                        window.history.replaceState({}, document.title, window.location.pathname);
                    }

                    return true;
                } else {
                    throw new Error('Invalid token');
                }
            } catch (error) {
                console.error('Token validation failed:', error);
                return false;
            }
        }
        return false;
    }, []);

    const logout = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('company_id');
        localStorage.removeItem('user_id');
        setUser(null);
        window.location.href = __AUTH_URL__;  // Redirect to auth page
    }, []);

    useEffect(() => {
        // Listen for the global 'logout' event
        const handleLogoutEvent = () => logout();

        window.addEventListener('logout', handleLogoutEvent);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('logout', handleLogoutEvent);
        };
    }, [logout]);

    useEffect(() => {
        const initAuth = async () => {
            const urlToken = new URLSearchParams(window.location.search).get('token');
            const storedToken = localStorage.getItem('token');
            let tokenToUse = storedToken || urlToken;

            // Validate token only if we have one to use
            if (tokenToUse) {
                const isValid = await validateToken(tokenToUse);
                if (!isValid) {
                    logout();
                }
            } else {
                logout();  // No token available, force logout
            }

            setLoading(false);
        };

        initAuth();
    }, [validateToken, logout]);

    // Token expired or 401 response handler
    const handleUnauthorized = useCallback(() => {
        logout();  // Log out if token is invalid or expired
    }, [logout]);

    const value = {
        user,
        logout,
        loading,
        handleUnauthorized
    };

    if (loading) {
        return <LoadingComponent />;
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
