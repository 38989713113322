import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import routes from './constants/routeConfig';
import LoadingComponent from './common/LoadingComponent/LoadingComponent';
import { AuthProvider, useAuth } from './auth/AuthContext';
import AuthenticatedLayout from './auth/AuthenticatedLayout';
import NotFound from './pages/NotFound';
import { __AUTH_URL__ } from './constants/keyConstants';

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <LoadingComponent />;
  }

  return user ? children : <Navigate to={__AUTH_URL__} state={{ from: location }} replace />;
};

function AppContent() {

  const renderRoutes = (routeList) => {
    return routeList.map((route) => (
      route.children ? (
        <Route key={route.path} path={route.path} element={<route.Component />}>
          {renderRoutes(route.children)}
        </Route>
      ) : (
        <Route key={route.path} path={route.path} element={<route.Component />} />
      )
    ));
  };
  return (
    <Routes>
      <Route element={<PrivateRoute><AuthenticatedLayout /></PrivateRoute>}>
        {renderRoutes(routes)}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Suspense fallback={<LoadingComponent />}>
          <AppContent />
        </Suspense>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;