import { Box, TextField, Typography, Collapse, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  AdminPanelSettingsOutlined as UserVerificationIcon,
  BrandingWatermarkOutlined as ProductIcon,
  CalendarViewWeekOutlined as PipelineIcon,
  ContactPageOutlined as ContactIcon,
  CurrencyExchangeOutlined as DealIcon,
  HandshakeOutlined as ContractIcon,
  HomeRepairServiceOutlined as ProjectIcon,
  LibraryBooksOutlined as ExpensesIcon,
  ListAltOutlined as GeneralIcon,
  LocalAtmOutlined as SalesProposalIcon,
  PersonOutlineOutlined as UserIcon,
  ReceiptOutlined as InvoiceIcon,
  RequestQuoteOutlined as EstimateIcon,
  VerifiedUserOutlined as TwoFactorAuthIcon,
  WorkHistoryOutlined as BusinessHoursIcon,
  WorkOutlineOutlined as JobIcon,
  ExpandMore as ExpandMoreIcon,
  Workspaces as CRMIcon,
} from "@mui/icons-material";

import "./Settings.scss";

const SettingsLayout = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedItem, setExpandedItem] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/settings') {
      navigate('/settings/General');
    }
  }, [location.pathname, navigate]);

  const items = [
    { name: "General", icon: <GeneralIcon fontSize="small" />, subItems: ["BusinessName", "UserVerification", "TwoFA", "BusinessHours"] },
    { name: "Pipeline", icon: <PipelineIcon fontSize="small"/> },
    { name: "User", icon: <UserIcon fontSize="small"  /> },
    { name: "Sales", icon: <SalesProposalIcon fontSize="small"  />, subItems: ["Job", "Estimate", "Invoice", "SalesProposal", "Expenses"] },
    { name: "CRM", icon: <CRMIcon fontSize="small"  />, subItems: ["Contact", "Company", "Deal"] },
    { name: "Project", icon: <ProjectIcon fontSize="small"  />},
    { name: "Contact", icon: <ContactIcon fontSize="small"  /> },
    { name: "Product", icon: <ProductIcon fontSize="small"  /> },
  ];

  const filterItems = (items) => {
    return items.filter(item => {
      if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) return true;
      if (item.subItems) {
        return item.subItems.some(subItem =>
          subItem.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      return false;
    });
  };

  const filteredItems = filterItems(items);

  const formatItemName = (item) => item.replace(/([a-z])([A-Z])/g, '$1 $2');

  return (
    <div className="settingsPage container-fluid">
      <div className="d-flex gap-2 position-relative">
        <div className="leftPanel">
          <div className="white-container pt-2">
            <div className="pb-2">
              <TextField
                placeholder="Search"
                variant="standard"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
              />
            </div>
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => {
                const isSelected = location.pathname.includes(`/settings/${item.name}`);
                const isExpanded = expandedItem === item.name;

                return (
                  <Box key={item.name} className={`settingsNavItem ${isSelected ? "selColor" : ""}`}>
                    <Box
                      onClick={() => {
                        if (item.subItems) {
                          setExpandedItem(isExpanded ? null : item.name); // Toggle if has sub-items
                        } else {
                          navigate(`/settings/${item.name}`); // Navigate directly if no sub-items
                        }
                      }}
                      
                      sx={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                    >
                      <Stack direction="row" gap={1}>
                        {item.icon}
                        <Typography>{formatItemName(item.name)}</Typography>
                      </Stack>
                      {item.subItems && (
                        <ExpandMoreIcon fontSize="small"
                          sx={{
                            transform: isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
                            transition: "transform 0.3s ease"
                          }}
                        />
                      )}
                    </Box>
                    {item.subItems && (
                      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box >
                          {item.subItems
                            .filter(subItem => subItem.toLowerCase().includes(searchTerm.toLowerCase()))
                            .map((subItem) => {
                              const isSubItemSelected = location.pathname.includes(`/settings/${item.name}/${subItem}`);
                              return (
                                <Box
                                  key={subItem}
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent toggle of main item on sub-item click
                                    navigate(`/settings/${item.name}/${subItem}`);
                                  }}
                                  className={`subNavItem ${isSubItemSelected ? "subItemSelected" : ""}`} // Add selected class here
                                  sx={{ cursor: "pointer", display: "flex", alignItems: "center", gap: 1, }}
                                >
                                  <Typography variant="body2">{formatItemName(subItem)}</Typography>
                                </Box>
                              );
                            })}
                        </Box>
                      </Collapse>
                    )}
                  </Box>
                );
              })
            ) : (
              <Typography>No items found</Typography>
            )}
          </div>
        </div>
        <div className="rightPanel">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SettingsLayout;
